<template>
    <div>
    </div>
</template>

<script>
import jsPDF from 'jspdf';
import 'jspdf-autotable';
export default {
    data() {
        return {
            nome: 'produtos em estoque'
        }
    },
    created() {
       // this.gerar_pdf();
    },
    mounted(){
       this.gerar_pdf()
    },
    methods: {
        // gerando um pdf para teste;
        gerar_pdf() {
            const doc = new jsPDF();
            const titulo = "Relatório Financeiro";
            const header = ["Descrição", "Valor", "Info"];
            const dados2 = ["Dinheiro",'asd'];
            const dados3 = ["Pix", `asd`];
            const dados4 = ["Credito", `qsd`];
            const dados5 = ["Debito", `asd`];
            const dados6 = ["Duplicata", `qwe`];
            const dados7 = ["Cheque", `qwe`];
            const dados8 = ["Desconto", `adf`];
            const dados9 = ["Picpay", `adf`];
            const dados10 = ["Transferência", `adf`, `adf`];
            const dados11 = ["Cancelamentos", `adf`, `adf`];
            const footer = ["Total Geral", "adf", ""];
            const footer2 = ["Suprimento", "adf", ""];
            const footer3 = ["Sangria", "adf", ""];
            const footer4 = ["Vendas", "adf", ""];
            const footer5 = ["Saldo Inicial", "adf", ""];

            // Adicionar o header e o foote
            doc.text(titulo, 80, 10,);
            doc.autoTable({
                head: [header],
                body:
                    [
                        dados2, dados3,
                        dados4, dados5,
                        dados6, dados7,
                        dados8, dados9,
                        dados10, dados11,
                    ],
                foot: [footer5, footer2, footer3, footer4, footer],
            });

            // Salvar o documento
            doc.save("relatorio.pdf");

        },
        // criar uma endponit para trazer as informações do produtos em estoque;
        get_produtos_estoque() {

        },
    }
}
</script>

<style></style>